@use "sass:color";

$color-bg: #31202a;
$color-neon: #ff14bc;

:root {
  --color-bg: #{$color-bg};
  --color-neon: #{$color-neon};
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-bg);
}

#App {
  display: flex;
  width: 100%;
  height: 100%;
}

@mixin glow {
  text-shadow: 0 0 0.125em rgba(255, 110, 205, 0.85), 0 0 0.35em currentColor;
}

@mixin text-box {
  text-align: center;
  border: 0px;
  line-height: 2em;
  background: transparent;
  color: var(--color-neon);
  font-size: 48px;
  font-family: neon2mono;
  @include glow;
  overflow-y: hidden;
}

.titleTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-wrap: nowrap;
  overflow: hidden;
  padding: 4px;
  textarea,
  p {
    @include text-box;
    resize: none;
    overflow: scroll;

    &::placeholder {
      opacity: 50%;
    }
  }
}
.targetLengthWrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 60px;
  padding-right: 20px;
  border-radius: 20px 20px 0px 0px;
  box-shadow: inset 16px 16px 32px #{color.adjust($color-bg, $lightness: -1.6%)},
    inset -16px -16px 32px #{color.adjust($color-bg, $lightness: 1.5%)};

  input {
    &[type="range"] {
      flex-grow: 1;
      appearance: none;
      height: 8px;
      border-radius: 100px;
      opacity: 0.7;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }

      &::-webkit-slider-thumb,
      &::-moz-range-thumb {
        appearance: none;
        width: 24px;
        height: 24px;
        border-radius: 100px;
        background: var(--color-neon);
        cursor: pointer;
        position: relative;
      }
    }
    &[type="number"] {
      @include text-box;
      width: 2.2em;
    }
  }
}
